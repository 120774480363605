import React from 'react';

interface AlertProps {
    message: string;
    onClose: () => void;
    type?: 'success' | 'error' | 'warning' | 'info';
}

const Alert: React.FC<AlertProps> = ({ message, onClose, type = 'info' }) => {
    const alertTypeClass = {
        success: 'alert-success',
        error: 'alert-error',
        warning: 'alert-warning',
        info: 'alert-info',
    }[type];

    return (
        <div className={`alert ${alertTypeClass} shadow-lg`}>
            <div>
                <span>{message}</span>
            </div>
            <div className="flex-none">
                <button className="btn btn-sm btn-ghost" onClick={onClose}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="inline-block w-6 h-6 stroke-current"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Alert;