import React from 'react';
import { Navigate} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


interface PrivateRouteProps {
    children: React.ReactNode;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        // Show a loading indicator while authentication status is being determined
        return (
            <div className="flex items-center justify-center min-h-screen">
                <p>Loading...</p>
            </div>
        );
    }

    if (!isAuthenticated) {
        // Redirect to sign-in page if not authenticated
        return <Navigate to="/signin" />;
    }

    return <>{children}</>;
};

export default PrivateRoute;