import React from 'react';
import { FaBars, FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NavItem from '../NavItem/NavItem';
import { useAuth } from '../../context/AuthContext';
import { navigationLinks } from '../NavItem/navigationLinks';

interface NavbarProps {
    isMobileMenuOpen: boolean;
    toggleMobileMenu: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ isMobileMenuOpen, toggleMobileMenu }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const onLogout = async () => {
        await logout();
        navigate('/signin');
    };

    return (
        <div className="navbar bg-base-300 relative">
            {/* Left Side */}
            <div className="flex-none">
                {/* Burger Menu Icon on Mobile */}
                <button
                    className="btn btn-ghost btn-circle md:hidden"
                    onClick={toggleMobileMenu}
                >
                    <FaBars className="text-xl" />
                </button>
            </div>

            {/* Center */}
            <div className="flex-1">
                {/* PT Logo centered on mobile */}
                <Link
                    to="/"
                    className="btn btn-ghost normal-case text-xl mx-auto md:mx-0 md:hidden"
                >
                    {/* Show "PT" on mobile */}
                    <span className="md:hidden">PT</span>
                </Link>
            </div>

            {/* Right Side */}
            <div className="flex-none">
                <div className="flex space-x-4 items-center">
                    {/* Icons displayed only on medium and larger screens */}
                    <div className="hidden md:flex space-x-4">
                        {/* Map over navigationLinks for desktop menu */}
                        {navigationLinks
                            .filter((link) => link.showInDesktopMenu)
                            .map((link) => (
                                <div key={link.to} className="dropdown dropdown-end">
                                    <label tabIndex={0} className="btn btn-ghost btn-circle">
                                        <link.icon className="text-xl" />
                                    </label>
                                    <ul
                                        tabIndex={0}
                                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                                    >
                                        <li>
                                            <Link to={link.to}>{link.label}</Link>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                    </div>

                    {/* User Icon with Profile/Logout Dropdown */}
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-circle">
                            <FaUserCircle className="text-xl" />
                        </label>
                        <ul
                            tabIndex={0}
                            className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                        >
                            <li>
                                <a>Profile</a>
                            </li>
                            <li>
                                <button onClick={onLogout}>Logout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Mobile Menu Dropdown */}
            {isMobileMenuOpen && (
                <div className="absolute top-full left-0 w-full bg-base-100 md:hidden">
                    <ul className="menu p-2">
                        {navigationLinks
                            .filter((link) => link.showInMobileMenu)
                            .map((link) => (
                                <NavItem
                                    key={link.to}
                                    to={link.to}
                                    label={link.label}
                                    icon={link.icon}
                                    onClick={toggleMobileMenu}
                                />
                            ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Navbar;

