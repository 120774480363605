import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import axios from 'axios';

interface User {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    email_confirmed: boolean;
    // Add other user properties as needed
}

interface AuthContextType {
    isAuthenticated: boolean;
    isLoading: boolean;
    user: User; // Define a proper User type as needed
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    getStatus: () => Promise<void>;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [user, setUser] = useState<any>(null);

    const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;

    const checkAuthStatus = async () => {
        try {
            const response = await axios.get(`${apiEndpoint}/api/user/profile`, {
                withCredentials: true,
            });

            if (response.status === 200) {
                setIsAuthenticated(true);
                setUser(response.data);
            } else {
                setIsAuthenticated(false);
                setUser(null);
            }
        } catch (error) {
            setIsAuthenticated(false);
            setUser(null);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        checkAuthStatus();
    }, [apiEndpoint]);

    const getStatus = async () => {
        setIsLoading(true);
        await checkAuthStatus();
    };

    const login = async (email: string, password: string) => {
        setIsLoading(true);

        try {
            await axios.post(
                `${apiEndpoint}/api/auth/signin`, 
                { email, password },
                { withCredentials: true }
            );

            await checkAuthStatus();
        } catch (error) {
            console.error('Error during login:', error);
            setIsAuthenticated(false);
            setUser(null);
            throw error; 
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async () => {
        try {
            await axios.post(`${apiEndpoint}/api/user/logout`, {}, { withCredentials: true });
        } catch (error) {
            console.error('Error during logout:', error);
        } finally {
            setIsAuthenticated(false);
            setUser(null);
        }
    };

    return (
        <AuthContext.Provider
            value={{ isAuthenticated, isLoading, user, setUser, getStatus, login, logout }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};